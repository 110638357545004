import React, { useState, useMemo, useEffect } from 'react';
import JoditEditor from "jodit-react";

interface NoteProps {
  id: number;
  text: string;
  onChange: (id: number, value: string) => void;
  onDelete?: (id: number) => void;
  style?: React.CSSProperties;
}

const Note: React.FC<NoteProps> = ({ id, text, onChange, style }) => {
  const [markdownText, setMarkdownText] = useState(text);

  useEffect(() => {
      setMarkdownText(text);
  }, [text]);

  const handleChange = (value: string) => {
    setMarkdownText(value);
    onChange(id, value);
  };

  return useMemo( () => ( 
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...style
      }}
    >
      <JoditEditor
        value={markdownText}
        config={{
          statusbar: false,
        }}
        onChange={handleChange}
      />
    </div>
    // eslint-disable-next-line
  ), [] );
};

export default Note;
