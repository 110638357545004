import * as React from 'react';
import Note from './components/Note';
import Header from "./components/Header"
import { encode, decode } from 'base64-arraybuffer'
const pako = require('pako');

interface INote {
  id: number;
  text: string;
}

const App: React.FC = () => {
  const [notes, setNotes] = React.useState<INote[]>([]);
  const [nextId, setNextId] = React.useState(1);
  const [hash, setHash] = React.useState<string>("")

  const handleAddNote = (id: number) => {
    const newNote: INote = { id: nextId, text: "" };
    const newNotes = notes.slice();
    let found = false;
    for(let i = 0; i < newNotes.length; i++) {
        if(newNotes[i].id === id) {
            newNotes.splice(i+1, 0, newNote);
            found = true;
            break;
        }
    }
    if(!found) {
        newNotes.push(newNote);
    }
    setNotes(newNotes);
    setNextId(prevId => prevId + 1);
  };


  const handleChangeNote = (id: number, value: string) => {
    setNotes(prevNotes => {
      let newNotes = [...prevNotes];
      newNotes.forEach(note => {
        if (note.id === id) {
          note.text = value;
        }
      });
      return newNotes;
    });
  };

  const handleDeleteNote = (id: number) => {
      setNotes(prevNotes => {
        return prevNotes.filter(note => note.id !== id);
      });
  };

  const generateEncoding =  () => {
    const notesData = notes.map(note => ({ id: note.id, text: note.text }));
    const input = JSON.stringify(notesData);
    const compressed = pako.deflate(input);
    const encoded = encode(compressed);
    setHash(encoded);
    window.location.hash = encoded;
    navigator.clipboard.writeText(window.location.href);
}

React.useEffect(() => {
  const decodeEncoding = (encoded: string) => {
    const buff = decode(encoded);
    const appState = pako.inflate(buff, { to: 'string' });
    const new_notes = JSON.parse(appState) || [];
    setNotes(new_notes);
    setNextId(new_notes.length ? Math.max(...new_notes.map((note: INote) => note.id)) + 1 : 1);
  }

  if (window.location.hash) {
    decodeEncoding(window.location.hash.slice(1));
  } else {
    const initialNote: INote = { id: 1, text: "" };
    setNotes([initialNote]);
    setNextId(nextId + 1);
  }
  // eslint-disable-next-line
}, []);


return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Header />
        <div style={{ flex: 1 }}>
        {notes.map((note, index) => (
          <div key={note.id}>
            <Note
            id={note.id}
            text={note.text}
            onChange={(id, value) => handleChangeNote(id, value)}
          />
            <button onClick={() => handleAddNote(note.id)}>+</button>
            {notes.length > 1 && <button onClick={() => handleDeleteNote(note.id)}>-</button>}
          </div>
        ))}
        </div>
        <button onClick={generateEncoding}>Copy Page</button>
        {hash && <div> Saved! </div>}
      </div>
    );
}

export default App;